import React, { useState, useEffect, useRef } from "react";
//i18n
import { withTranslation } from "react-i18next";
import Select, { components } from 'react-select';
// import { Col, Row, Card, CardBody } from "reactstrap";
import ".././../assets/css/custom.css";
import avatar from ".././../assets/images/profile.png";
import girlAvatar from ".././../assets/images/profile.png";
import referrerAvatar from ".././../assets/images/stethoscope.png";
import calendar from ".././../assets/images/calendar.png";
import { Button, Col, Row, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Form, Input, FormFeedback, Label, Card, CardBody, FormGroup, Badge } from "reactstrap";
import {
    Container
} from "reactstrap";
// import Select from "react-select";
import * as Yup from "yup";
import ".././../assets/css/custom.css";
import { NavItem, NavLink, TabContent, TabPane, Nav } from "reactstrap";
import classnames from "classnames"
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { ENDPOINT } from "../Authentication/api/index"
import { API } from "../Authentication/api/index"
import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper"
import { useFormik } from "formik";
import axios from "axios"
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Spinners from "../../components/Common/Spinner";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as api from "../../pages/Authentication/api/index"
import TextEditor from "./TextEditor";
import reportApproval from "pages/reportApproval";
import ApproveModal from "components/Common/ApproveModel";
import RejectModal from "components/Common/RejectModel";
import Logout from "pages/Authentication/Logout";
import moment from 'moment';

const EchoReportEntry = props => {

    const navigate = useNavigate()

    const { editId } = useParams()

    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const [reportNo, setReportNo] = useState('');
    const [updatedBy, setUpdatedBy] = useState(null);
    const [approvedBy, setApprovedBy] = useState(null);

    const [template, setTemplate] = useState({ label: 'Select Template', value: 0 });
    const [rightDiasole, setRightDiasole] = useState('');
    const [templateFilterOption, setTemplateFilterOptions] = useState([]);

    const [templateOptions, setTemplateOptions] = useState([{ 'rpt_t_name': 'Select Template', 'rpt_tid': 0 }])

    useEffect(() => {
        document.getElementById('headerTitle').innerHTML = 'Echo Report Generator';
        bindMasterDropdown()
        getNormalDefault()
        getReportTemplateList()
    }, []);

    const handleTemplate = (inputValue) => {
        if (inputValue.length > 2) {

            const filtered = templateOptions.filter(hp =>
                hp.rpt_t_name.toLowerCase().startsWith(inputValue.toLowerCase().trim().slice(0, 3))
            );
            setTemplateFilterOptions(filtered);
        }
    };

    const [mitralselectedOptions, setMitralSelectedOptions] = useState([]);
    const [leftAtriumselectedOptions, setLeftAtriumSelectedOptions] = useState([]);
    const [aorticValueselectedOptions, setAorticValuesSelectedOptions] = useState([]);
    const [rightAtriumselectedOptions, setRightAtriumSelectedOptions] = useState([]);
    const [tricuspidValueselectedOptions, setTricuspidValueSelectedOptions] = useState([]);
    const [rightVentricalselectedOptions, setRightVentricalSelectedOptions] = useState([]);
    const [pulmonaryValueselectedOptions, setPulmonaryValueSelectedOptions] = useState([]);
    const [greatselectedOptions, setGreatSelectedOptions] = useState([]);
    const [iasselectedOptions, setIasSelectedOptions] = useState([]);
    const [percardiumselectedOptions, setPercardiumSelectedOptions] = useState([]);

    const [leftVentricalselectedOptions, setLeftVentricalSelectedOptions] = useState([]);
    const [impressionselectedOptions, setImpressionSelectedOptions] = useState([]);

    const user = getLoggedInUser();
    const [statusFlag, setStatusFlag] = useState();
    const [isLoading, setLoading] = useState(false);

    const [impressionValue, setImpressionValue] = useState('');
    const [leftVentricalValue, setLeftVentricalValue] = useState('');
    const [percardiumValue, setPercardiumValue] = useState('');
    const [iasValue, setIasValue] = useState('');
    const [greatVesselsValue, setGreatVesselsValue] = useState('');
    const [pulmonaryValue, setPulmonaryValue] = useState('');
    const [rightVentricalValue, setRightVentricalValue] = useState('');
    const [tricuspidValue, setTricuspidValue] = useState('');
    const [rightAtriumValue, setRightAtriumValue] = useState('');
    const [aorticValue, setAorticValue] = useState('');
    const [leftAtriumValue, setLeftAtriumValue] = useState('');
    const [mitralValue, setMitralValue] = useState('');

    const [langugeTab, changeLangugeTab] = useState('11');
    const [tal1, setTab1] = useState(false);
    const [tab2, setTab2] = useState(false);
    const [tab3, setTab3] = useState(false);
    const [tab4, setTab4] = useState(false);
    const [tab5, setTab5] = useState(false);
    const [reportStatus, setReportStatus] = useState(1);
    const [aorticroot, setAorticRoot] = useState('');
    const [leftatrium, setLeftAtrium] = useState('');
    const [leftDiastole, setLeftDiastole] = useState('');
    const [systole, setSystole] = useState('');
    const [ivs, setIvs] = useState('');
    const [lvpw, setLvpw] = useState('');
    const [lvef, setLvef] = useState('');
    const [rightDiastole, setRightDiastole] = useState('');
    const [templateName, settemplateName] = useState('');
    const [a, seta] = useState('');
    const [e, sete] = useState('');
    const [aorticTab2, setAorticTab2] = useState('');
    const [tricuspid, setTricuspid] = useState('');
    const [pulmonary, setPulmonary] = useState('');
    const [editorData, setEditorData] = useState('');
    const [ea, setEA] = useState(0);
    const [chipValue, setChipValue] = useState({});
    const [chipValueOptions, setChipValueOptions] = useState('');

    const [aorticRootDefault, setAorticRootDefault] = useState('');
    const [leftAtriumDefault, setLeftAtriumDefault] = useState('');
    const [leftVentricalDiastoleDefault, setLeftVentricalDiastoleDefault] = useState('');
    const [systoleDefault, setSystoleDefault] = useState('');
    const [ivsDefault, setIvsDefault] = useState('');
    const [lvpwDefault, setLvpwDefault] = useState('');
    const [lvefDefault, setLvefDefault] = useState('');
    const [rightVentricalDiastoleDefault, setRightVentricalDiastoleDefault] = useState('');

    const [mitralEditorTab2, setMitralEditorTab2] = useState('');
    const [aorticEditorTab2, setAorticEditorTab2] = useState('');
    const [tricuspidEditorTab2, setTricuspidEditorTab2] = useState('');
    const [pulmonaryEditorTab2, setPulmonaryEditorTab2] = useState('');

    const [mitralOptions, setMitralOptions] = useState([]);
    const [leftAtriumOptions, setLeftAtriumOptions] = useState([]);
    const [aorticValueOptions, setAorticValuesOptions] = useState([]);
    const [rightAtriumOptions, setRightAtriumOptions] = useState([]);
    const [tricuspidValueOptions, setTricuspidValueOptions] = useState([]);
    const [rightVentricalOptions, setRightVentricalOptions] = useState([]);
    const [pulmonaryValueOptions, setPulmonaryValueOptions] = useState([]);
    const [greatOptions, setGreatOptions] = useState([]);
    const [iasOptions, setIasOptions] = useState([]);
    const [percardiumOptions, setPercardiumOptions] = useState([]);
    const [leftVentricalOptions, setLeftVentricalOptions] = useState([]);
    const [impressionOptions, setImpressionOptions] = useState([]);

    const mitralValueIframeRef = useRef(null)
    const leftAtriumIframeRef = useRef(null)
    const aorticValueIframeRef = useRef(null)
    const rightAtriumIframeRef = useRef(null)
    const tricuspidValueIframeRef = useRef(null)
    const rightVentricleValueIframeRef = useRef(null)
    const pulmonaryValueIframeRef = useRef(null)
    const greatvesselsValueIframeRef = useRef(null)
    const iasValueIframeRef = useRef(null)
    const pericardiumValueIframeRef = useRef(null)
    const leftventricleValueIframeRef = useRef(null)
    const impressionValueIframeRef = useRef(null)
    const [isEdit, setIsEdit] = useState(false);
    const [tab1Flag, setTab1Flag] = useState(false);
    const [tab2Flag, setTab2Flag] = useState(false);
    const [tab3Flag, setTab3Flag] = useState(false);
    const [tab4Flag, setTab4Flag] = useState(false);
    const mitralEditorTab2Ref = useRef(null);
    const aorticEditorTab2Ref = useRef(null);
    const tricuspidEditorTab2Ref = useRef(null);
    const pulmonaryEditorTab2Ref = useRef(null);
    const [patientDetails, setPatientDetails] = useState({ id: 0, patientname: '', uhid: '', age: 0, gender: "", address: "", contact_no: "", stsid: "", city: "" });

    const [hospitalDetails, setHospitalDetails] = useState({ id: 0, referrername: '', hospitalname: '', referrerid: 0 });

    const [reportDetails, setReportDetails] = useState({ reportdate: '', reportnumber: '' });



    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setEditorData(data);
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            aorticroot: (aorticroot) || '',
            leftatrium: (leftatrium) || '',
            leftdiastole: (leftDiastole) || '',
            systole: (systole) || '',
            ivs: (ivs) || '',
            lvpw: (lvpw) || '',
            lvef: (lvef) || '',
            rightdiastole: (rightDiastole) || '',
        },
        validationSchema: Yup.object({
            aorticroot: Yup.string().required("Please enter aortic root value"),
            leftatrium: Yup.string().required("Please enter left atrium value"),
            leftdiastole: Yup.string().required("Please enter left diastole value"),
            systole: Yup.string().required("Please enter systole value"),
            ivs: Yup.string().required("Please enter ivs value"),
            lvpw: Yup.string().required("Please enter lvpw value"),
            lvef: Yup.string().required("Please enter lvef value"),
            rightdiastole: Yup.string().required("Please enter right diastole value"),
        }),
        onSubmit: (values, { }) => {
            setTab1Flag(true)
            changeLangugeTab("12")
            setTab1(true)
        },
    });

    const tab2validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            e: (e) || '',
            a: (a) || '',
            aorticTab2: (aorticTab2) || '',
            tricuspid: (tricuspid) || '10',
            pulmonary: (pulmonary) || '5',
        },
        validationSchema: Yup.object({
            e: Yup.string().required("Please enter e value"),
            a: Yup.string().required("Please enter a value"),
            aorticTab2: Yup.string().required("Please enter aortic valve"),
            tricuspid: Yup.string().required("Please enter tricuspid valve"),
            pulmonary: Yup.string().required("Please enter pulmonary valve"),
        }),
        onSubmit: (values, { }) => {
            console.log('fgfdg', values.aorticTab2);
            setTab2Flag(true)
            changeLangugeTab("13")
            setTab2(true)
        },
    });

    const editorValuesCheck = () => {

        var mitrlhtmlContent = ''
        var aortichtmlContent = ''
        var tricuspidhtmlContent = ''
        var pulmonaryhtmlContent = ''

        const editor1 = mitralEditorTab2Ref.current.getEditor();
        mitrlhtmlContent = editor1.getText().trim();

        const editor2 = aorticEditorTab2Ref.current.getEditor();
        aortichtmlContent = editor2.getText().trim();

        const editor3 = tricuspidEditorTab2Ref.current.getEditor();
        tricuspidhtmlContent = editor3.getText().trim();

        const editor4 = pulmonaryEditorTab2Ref.current.getEditor();
        pulmonaryhtmlContent = editor4.getText().trim();

        if (mitrlhtmlContent === '') {
            toast.error('Please enter mitral editor value')
        } else if (aortichtmlContent === '') {
            toast.error('Please enter aortic editor value')
        } else if (tricuspidhtmlContent === '') {
            toast.error('Please enter tricuspid editor value')
        } else if (pulmonaryhtmlContent === '') {
            toast.error('Please enter pulmonary editor value')
        }

        if (mitrlhtmlContent !== '' && aortichtmlContent !== '' && tricuspidhtmlContent !== '' && pulmonaryhtmlContent !== '') {
            setTab2Flag(true)
            changeLangugeTab("13")
            setTab2(true)
        }
    }
    const handleKeyDown = (e) => {

        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();
        }
    };
    const handleWheel = (e) => {
        // Prevent the input value change
        e.target.blur()

        // Prevent the page/container scrolling
        e.stopPropagation()

        // // Refocus immediately, on the next tick (after the current     
        // function is done)
        //   setTimeout(() => {
        //     e.target.focus()
        // }, 0)
    };


    const tab3EditorValuesCheck = () => {

        var mitrlhtmlContent = ''
        var leftatriumhtmlContent = ''
        var aortichtmlContent = ''
        var rightatriumhtmlContent = ''
        var tricuspidhtmlContent = ''
        var leftventricalhtmlContent = ''
        var pulmonaryhtmlContent = ''
        var greathtmlContent = ''
        var iashtmlContent = ''
        var percardiumhtmlContent = ''

        const editor1 = mitralValueIframeRef.current.getEditor();
        mitrlhtmlContent = editor1.getText().trim();

        const editor2 = leftAtriumIframeRef.current.getEditor();
        leftatriumhtmlContent = editor2.getText().trim();

        const editor3 = aorticValueIframeRef.current.getEditor();
        aortichtmlContent = editor3.getText().trim();

        const editor4 = rightAtriumIframeRef.current.getEditor();
        rightatriumhtmlContent = editor4.getText().trim();

        const editor5 = tricuspidValueIframeRef.current.getEditor();
        tricuspidhtmlContent = editor5.getText().trim();

        const editor6 = rightVentricleValueIframeRef.current.getEditor();
        leftventricalhtmlContent = editor6.getText().trim();

        const editor7 = pulmonaryValueIframeRef.current.getEditor();
        pulmonaryhtmlContent = editor7.getText().trim();

        const editor8 = greatvesselsValueIframeRef.current.getEditor();
        greathtmlContent = editor8.getText().trim();

        const editor9 = iasValueIframeRef.current.getEditor();
        iashtmlContent = editor9.getText().trim();

        const editor10 = pericardiumValueIframeRef.current.getEditor();
        percardiumhtmlContent = editor10.getText().trim();

        if (mitrlhtmlContent === '') {
            toast.error('Please enter mitral editor value')
        } else if (leftatriumhtmlContent === '') {
            toast.error('Please enter left atrium editor value')
        } else if (aortichtmlContent === '') {
            toast.error('Please enter aortic editor value')
        } else if (rightatriumhtmlContent === '') {
            toast.error('Please enter right atrium editor value')
        } else if (tricuspidhtmlContent === '') {
            toast.error('Please enter tricuspid editor value')
        } else if (leftventricalhtmlContent === '') {
            toast.error('Please enter right ventrical editor value')
        } else if (pulmonaryhtmlContent === '') {
            toast.error('Please enter pulmonary editor value')
        } else if (greathtmlContent === '') {
            toast.error('Please enter great vessels editor value')
        } else if (iashtmlContent === '') {
            toast.error('Please enter ias and avs editor value')
        } else if (percardiumhtmlContent === '') {
            toast.error('Please enter percardium editor value')
        }

        if (mitrlhtmlContent !== '' && leftatriumhtmlContent !== '' && aortichtmlContent !== '' && rightatriumhtmlContent !== '' && tricuspidhtmlContent !== '' && leftventricalhtmlContent !== '' && pulmonaryhtmlContent !== '' && greathtmlContent !== '' && iashtmlContent !== '' && percardiumhtmlContent !== '') {
            setTab3Flag(true)
            changeLangugeTab("14")
            setTab3(true)
        }
    }

    const tab4EditorValuesCheck = (value) => {

        var leftventricalhtmlContent = ''
        var impressionhtmlContent = ''

        const editor1 = leftventricleValueIframeRef.current.getEditor();
        leftventricalhtmlContent = editor1.getText().trim();

        const editor2 = impressionValueIframeRef.current.getEditor();
        impressionhtmlContent = editor2.getText().trim();

        if (leftventricalhtmlContent === '') {
            toast.error('Please enter mitral editor value')
        } else if (impressionhtmlContent === '') {
            toast.error('Please enter aortic editor value')
        }

        console.log(reportStatus, 'reportStatus');


        if (leftventricalhtmlContent !== '' && impressionhtmlContent !== '') {
            setTab4Flag(true)
            // changeLangugeTab("13")
            // setTab2(true)
            if (reportStatus === 3) {
                saveReport()
            }
            if (tab1Flag === true && tab2Flag === true && tab3Flag === true && value === 1) {
                saveReport()
            }
            if (value === 2) {
                setApproveModal(true)
                // reportApprove()
            }
            if (value === 3) {
                // reportReject()
                setRejectModal(true)
            }
        }
    }

    // Save And Update Call
    const saveReport = () => {
        setButtonDisabled(true)
        console.log(tab1Flag, tab2Flag, tab3Flag);

        // if (tab1Flag === true && tab2Flag === true && tab3Flag === true) {

        const req = {
            user_id: Number(user.uid),
            rpt_update_id: Number(editId),
            ptid: Number(patientDetails.id),
            rpt_checker: 2,
            rpt_status: 1,
            process_type: "update",
            rpt_report_no: reportDetails.reportnumber,
            rpt_report_date: reportDetails.reportdate,
            rpt_echodiagram_data: {
                aorticroot_value: Number(validation.values.aorticroot) || '',
                leftatrium_value: Number(validation.values.leftatrium) || '',
                left_ventrical_diastole: Number(validation.values.leftdiastole) || '',
                systole_value: Number(validation.values.systole) || '',
                ivs_value: Number(validation.values.ivs) || '',
                lvpw_value: Number(validation.values.lvpw) || '',
                lvef_value: Number(validation.values.lvef) || '',
                right_ventrical_diastole: Number(validation.values.rightdiastole) || ''
            },
            rpt_doopler_data: {
                a_value: tab2validation.values.a || '',
                e_value: tab2validation.values.e || '',
                e_divide_a_value: ea || '',
                aortic: Number(tab2validation.values.aorticTab2) || '',
                tricuspid: Number(tab2validation.values.tricuspid) || '',
                pulmonary: Number(tab2validation.values.pulmonary) || '',
                mitral_editor_value: mitralEditorTab2 || '',
                aortic_editor_value: aorticEditorTab2 || '',
                tricuspid_editor_value: tricuspidEditorTab2 || '',
                pulmonary_editor_value: pulmonaryEditorTab2 || ''
            },
            rpt_color_doopler_data: {
                mitralvalue_editor_value: mitralValue || '',
                leftatrium_editor_value: leftAtriumValue || '',
                aorticvalue_editor_value: aorticValue || '',
                rightatrium_editor_value: rightAtriumValue || '',
                tricuspidvalue_editor_value: tricuspidValue || '',
                rightventical_editor_value: rightVentricalValue || '',
                pulmonaryvalue_editor_value: pulmonaryValue || '',
                greatvessels_editor_value: greatVesselsValue || '',
                ias_editor_value: iasValue || '',
                percardium_editor_value: percardiumValue || ''
            },
            rpt_left_ventricle: {
                leftventrical_editor_value: leftVentricalValue || ''
            },
            rpt_impression: {
                impression_editor_value: impressionValue || ''
            },
            rpt_patient_data: {
                ptid: patientDetails.id,
                patient_age: patientDetails.age,
                patient_name: patientDetails.patientname,
                patient_uhid: patientDetails.uhid,
                patient_gender: patientDetails.gender,
                patient_gender_value: patientDetails.gender === 1 ? 'Male' : 'Female' || patientDetails.gender === 3 && 'Transgender'
            },
            rpt_hospital_data: {
                hospital_id: hospitalDetails.id,
                hospital_name: hospitalDetails.hospitalname
            },
            rpt_doctor_data: {
                referrer_id: hospitalDetails.referrerid,
                referrer_name: hospitalDetails.referrername
            },

            pt_name: patientDetails.patientname,
            pt_stsid: 1,
            pt_hospital_id: hospitalDetails.id,
            pt_uhid: patientDetails.uhid,
            pt_age: patientDetails.age,
            pt_gender: patientDetails.gender,
            pt_address: patientDetails.address,
            pt_city: patientDetails.city,
            pt_contact_number: patientDetails.contact_no,
            rpt_referrer_id: hospitalDetails.referrerid,
            rpt_hospital_id: hospitalDetails.id,
            rpt_patient_id: patientDetails.id,
            updated_by: updatedBy !== null ? Number(updatedBy) : updatedBy,
            approved_by: approvedBy !== null ? Number(approvedBy) : approvedBy
        }
        createTemplate(req)

        console.log(patientDetails.gender, 'gender');


    }

    console.log('patient Details', patientDetails);

    // API Save Template
    const createTemplate = async (req) => {
        try {
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const response = await axios.post(process.env.REACT_APP_BASE_URL + ENDPOINT.REPORTSAVE, req, config);
            setButtonDisabled(false)
            console.log('res', response)
            if (response) {

                if (response && response?.data?.body?.statusFlag === 3) {
                    toast.error(response?.data?.body?.message, { autoClose: 2000 });
                    setTimeout(() => {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    }, 2000);
                }

                if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                    const refresh = await getRefreshToken();

                    if (refresh) {
                        if (Number(refresh) === constants.invalidRefreshToken) {
                            localStorage.removeItem("authUser");
                            navigate('/login')
                        } else {
                            await createTemplate(req);
                        }
                    }
                }

                setStatusFlag(response?.data?.body?.statusFlag)
                if (response && response?.data?.body?.statusFlag === 4) {
                    // navigate('/report-request')
                    toast.success(response?.data?.body?.message, { autoClose: 2000 });
                    setTimeout(() => navigate('/report-request'), 700);
                } else if (response && response?.data?.body?.statusFlag === 2) {
                    toast.error(response?.data?.body?.message, { autoClose: 2000 });
                } else if (response && response?.data?.body?.statusFlag === 4) {
                    toast.success(response?.data?.body?.message, { autoClose: 2000 });
                }
                else {
                    toast.error(response?.data?.body?.message, { autoClose: 2000 })
                }
            }
        } catch (error) {
            setButtonDisabled(false)
            console.log('er', error)
            toast.error("Report Entry Failed", { autoClose: 2000 });
        }
    }

    async function bindMasterDropdown() {


        const data = {
            user_id: Number(user.uid)
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + api.API.DEFAULTMASTER + "/" + api.ENDPOINT.GETMASTERS, data, config);

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    navigate('/login')
                }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    } else {
                        await bindMasterDropdown();
                    }
                }
            }

            if (response?.data?.body?.MasterList) {


                setMitralOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 3))
                setLeftAtriumOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 8))
                setAorticValuesOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 4))
                setRightAtriumOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 14))
                setTricuspidValueOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 5))
                setRightVentricalOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 9))
                setPulmonaryValueOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 6))
                setGreatOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 10))
                setIasOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 7))
                setPercardiumOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 11))
                setLeftVentricalOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 12))
                setImpressionOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 13))
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            setLoading(false)
            console.error(" master List API Error:", error);
        }

    }
    // EditLoadApi Call
    useEffect(() => {
        if (editId != "0" && editId != null && editId != undefined) {
            editLoad(editId)
        }

    }, [editId])

    // Edit Load API
    const editLoad = async (rpt_id) => {
        console.log('rpt_id', rpt_id);

        setLoading(true)

        var data = {
            user_id: Number(user.uid),
            rpt_id: Number(rpt_id),
        }

        const config = {
            headers: { authorization: "Bearer ".concat(user.accessToken) },
        }
        const response1 = await axios.post(process.env.REACT_APP_BASE_URL + ENDPOINT.REPORTENTRYEDITLOAD, data, config);


        if (response1 && response1?.data?.body?.statusFlag === 3) {
            toast.error(response1?.data?.body?.message, { autoClose: 2000 });
            setTimeout(() => {
                localStorage.removeItem("authUser");
                navigate('/login')
            }, 2000);
        }

        if (
            response1?.data?.body?.status === "401" ||
            response1?.data?.body?.status === 401
        ) {
            const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
            if (refresh) {
                if (Number(refresh) === constants.invalidRefreshToken) {
                    localStorage.removeItem("authUser");
                    navigate('/login')
                } else {
                    await editLoad(rpt_id) // calling same function again getting refresh token
                }
            }
        }
        if (
            response1 &&
            response1?.data &&
            response1?.data?.body &&
            response1?.data?.body?.templateData?.length > 0
        ) {
            const templateData = response1?.data?.body?.templateData[0]
            const dopplerData = response1?.data?.body?.dopplerData[0]
            const LeftventricleData = response1?.data?.body?.leftventricleData[0]
            const impressionData = response1?.data?.body?.impressionData[0]

            setPatientDetails({ id: templateData?.rpt_patient_data?.ptid, patientname: templateData?.rpt_patient_data?.patient_name, uhid: templateData?.rpt_patient_data?.patient_uhid, age: templateData?.rpt_patient_data?.patient_age, gender: templateData?.rpt_patient_data?.patient_gender, city: templateData?.rpt_patient_data?.pt_city, address: templateData?.rpt_patient_data?.pt_address, contact_no: templateData?.rpt_patient_data?.pt_contact_number })

            setHospitalDetails({ id: templateData?.rpt_hospital_data?.hospital_id, hospitalname: templateData?.rpt_hospital_data?.hospital_name, referrername: templateData?.rpt_doctor_data?.referrer_name, referrerid: templateData?.rpt_doctor_data?.referrer_id })

            setReportDetails({ reportdate: templateData?.date, reportnumber: templateData?.rpt_report_no })

            setUpdatedBy(templateData?.rpt_updated_by)
            setApprovedBy(templateData?.rpt_approved_by)

            // console.log(templateData?.rpt_echodiagram_data?.aorticroot_value);
            if (templateData?.rpt_echodiagram_data?.aorticroot_value !== undefined) {
                setIsEdit(true)
            }


            setAorticRoot(templateData?.rpt_echodiagram_data?.aorticroot_value)
            setLeftAtrium(templateData?.rpt_echodiagram_data?.leftatrium_value)
            setLeftDiastole(templateData?.rpt_echodiagram_data?.left_ventrical_diastole)
            setSystole(templateData?.rpt_echodiagram_data?.systole_value)
            setIvs(templateData?.rpt_echodiagram_data?.ivs_value)
            setLvpw(templateData?.rpt_echodiagram_data?.lvpw_value)
            setLvef(templateData?.rpt_echodiagram_data?.lvef_value)
            setRightDiastole(templateData?.rpt_echodiagram_data?.right_ventrical_diastole)
            seta(templateData?.rpt_doopler_data?.a_value)
            sete(templateData?.rpt_doopler_data?.e_value)
            tab2validation.setFieldValue('a',templateData?.rpt_doopler_data?.a_value)
            tab2validation.setFieldValue('e',templateData?.rpt_doopler_data?.e_value)
            setPulmonary(templateData?.rpt_doopler_data?.pulmonary)
            setAorticTab2(templateData?.rpt_doopler_data?.aortic)
            setTricuspid(templateData?.rpt_doopler_data?.tricuspid)
            setEA(templateData?.rpt_doopler_data?.e_divide_a_value)

            // // editor content

            // // tab2 editor values

            setMitralEditorTab2(templateData?.rpt_doopler_data?.mitral_editor_value != undefined ? templateData?.rpt_doopler_data?.mitral_editor_value : "")
            setAorticEditorTab2(templateData?.rpt_doopler_data?.aortic_editor_value != undefined ? templateData?.rpt_doopler_data?.aortic_editor_value : "")
            setTricuspidEditorTab2(templateData?.rpt_doopler_data?.tricuspid_editor_value != undefined ? templateData?.rpt_doopler_data?.tricuspid_editor_value : "")
            setPulmonaryEditorTab2(templateData?.rpt_doopler_data?.pulmonary_editor_value != undefined ? templateData?.rpt_doopler_data?.pulmonary_editor_value : "")

            //tab3 editor values 

            setMitralValue(templateData?.rpt_color_doopler_data?.mitralvalue_editor_value != undefined ? templateData?.rpt_color_doopler_data?.mitralvalue_editor_value : "")
            setLeftAtriumValue(templateData?.rpt_color_doopler_data?.leftatrium_editor_value != undefined ? templateData?.rpt_color_doopler_data?.leftatrium_editor_value : "")
            setAorticValue(templateData?.rpt_color_doopler_data?.aorticvalue_editor_value != undefined ? templateData?.rpt_color_doopler_data?.aorticvalue_editor_value : "")
            setRightAtriumValue(templateData?.rpt_color_doopler_data?.rightatrium_editor_value != undefined ? templateData?.rpt_color_doopler_data?.rightatrium_editor_value : "")
            setTricuspidValue(templateData?.rpt_color_doopler_data?.tricuspidvalue_editor_value != undefined ? templateData?.rpt_color_doopler_data?.tricuspidvalue_editor_value : "")
            setRightVentricalValue(templateData?.rpt_color_doopler_data?.rightventical_editor_value != undefined ? templateData?.rpt_color_doopler_data?.rightventical_editor_value : "")
            setPulmonaryValue(templateData?.rpt_color_doopler_data?.pulmonaryvalue_editor_value != undefined ? templateData?.rpt_color_doopler_data?.pulmonaryvalue_editor_value : "")
            setGreatVesselsValue(templateData?.rpt_color_doopler_data?.greatvessels_editor_value != undefined ? templateData?.rpt_color_doopler_data?.greatvessels_editor_value : "")
            setIasValue(templateData?.rpt_color_doopler_data?.ias_editor_value != undefined ? templateData?.rpt_color_doopler_data?.ias_editor_value : "")
            setPercardiumValue(templateData?.rpt_color_doopler_data?.percardium_editor_value != undefined ? templateData?.rpt_color_doopler_data?.percardium_editor_value : "")

            //tab4 editor values

            setLeftVentricalValue(templateData?.rpt_left_ventricle?.leftventrical_editor_value != undefined ? templateData?.rpt_left_ventricle?.leftventrical_editor_value : "")
            setImpressionValue(templateData?.rpt_impression?.impression_editor_value != undefined ? templateData?.rpt_impression?.impression_editor_value : "")

            //set report status

            setReportStatus(templateData?.rpt_status)

            setLoading(false)
        }
    }

    const templateDefaultLoad = (templateId) => {
        // setLoading(true)
        console.log('templateId', templateId);

        var templateDetails = templateOptions.filter(item => item.rpt_tid === templateId);

        console.log('templateDetails', templateDetails[0]?.rpt_t_color_doopler_data?.mitralvalue_editor_value);

        // editor content

        //tab3 editor values 

        setMitralValue(templateDetails[0]?.rpt_t_color_doopler_data?.mitralvalue_editor_value)
        setLeftAtriumValue(templateDetails[0]?.rpt_t_color_doopler_data?.leftatrium_editor_value)
        setAorticValue(templateDetails[0]?.rpt_t_color_doopler_data?.aorticvalue_editor_value)
        setRightAtriumValue(templateDetails[0]?.rpt_t_color_doopler_data?.rightatrium_editor_value)
        setTricuspidValue(templateDetails[0]?.rpt_t_color_doopler_data?.tricuspidvalue_editor_value)
        setRightVentricalValue(templateDetails[0]?.rpt_t_color_doopler_data?.rightventical_editor_value)
        setPulmonaryValue(templateDetails[0]?.rpt_t_color_doopler_data?.pulmonaryvalue_editor_value)
        setGreatVesselsValue(templateDetails[0]?.rpt_t_color_doopler_data?.greatvessels_editor_value)
        setIasValue(templateDetails[0]?.rpt_t_color_doopler_data?.ias_editor_value)
        setPercardiumValue(templateDetails[0]?.rpt_t_color_doopler_data?.percardium_editor_value)

        //tab4 editor values

        setLeftVentricalValue(templateDetails[0]?.rpt_t_left_ventricle?.leftventrical_editor_value)
        setImpressionValue(templateDetails[0]?.rpt_t_impression?.impression_editor_value)
    }

    useEffect(() => {
        if (template.value != 0) {
            templateDefaultLoad(template.value)
        }
    }, [template])

    // useEffect(() => {
    //     handlechangeEa()
    // }, [a])

    const [rejectModal, setRejectModal] = useState(false);
    const [approveModal, setApproveModal] = useState(false);

    const modules = {
        toolbar: [
            [{ bold: true }, { italic: true }, { underline: true }],
        ],
    };

    const formats = [
        "bold",
        "italic",
        "underline",
    ];

    const reportApprove = async () => {
        console.log('tab2validation.values.a',tab2validation.values.a);
        console.log('tab2validation.values.e',tab2validation.values.e);
        setButtonDisabled(true)
        const req = {
            user_id: Number(user.uid),
            rpt_update_id: Number(editId),
            ptid: Number(patientDetails.id),
            rpt_checker: 2,
            rpt_status: 2,
            process_type: "update",
            rpt_report_no: reportDetails.reportnumber,
            rpt_report_date: reportDetails.reportdate,
            rpt_echodiagram_data: {
                aorticroot_value: Number(validation.values.aorticroot) || '',
                leftatrium_value: Number(validation.values.leftatrium) || '',
                left_ventrical_diastole: Number(validation.values.leftdiastole) || '',
                systole_value: Number(validation.values.systole) || '',
                ivs_value: Number(validation.values.ivs) || '',
                lvpw_value: Number(validation.values.lvpw) || '',
                lvef_value: Number(validation.values.lvef) || '',
                right_ventrical_diastole: Number(validation.values.rightdiastole) || ''
            },
            rpt_doopler_data: {
                a_value: tab2validation.values.a || '',
                e_value: tab2validation.values.e || '',
                e_divide_a_value: ea || '',
                aortic: Number(tab2validation.values.aorticTab2) || '',
                tricuspid: Number(tab2validation.values.tricuspid) || '',
                pulmonary: Number(tab2validation.values.pulmonary) || '',
                mitral_editor_value: mitralEditorTab2 || '',
                aortic_editor_value: aorticEditorTab2 || '',
                tricuspid_editor_value: tricuspidEditorTab2 || '',
                pulmonary_editor_value: pulmonaryEditorTab2 || ''
            },
            rpt_color_doopler_data: {
                mitralvalue_editor_value: mitralValue || '',
                leftatrium_editor_value: leftAtriumValue || '',
                aorticvalue_editor_value: aorticValue || '',
                rightatrium_editor_value: rightAtriumValue || '',
                tricuspidvalue_editor_value: tricuspidValue || '',
                rightventical_editor_value: rightVentricalValue || '',
                pulmonaryvalue_editor_value: pulmonaryValue || '',
                greatvessels_editor_value: greatVesselsValue || '',
                ias_editor_value: iasValue || '',
                percardium_editor_value: percardiumValue || ''
            },
            rpt_left_ventricle: {
                leftventrical_editor_value: leftVentricalValue || ''
            },
            rpt_impression: {
                impression_editor_value: impressionValue || ''
            },
            rpt_patient_data: {
                ptid: patientDetails.id,
                patient_age: patientDetails.age,
                patient_name: patientDetails.patientname,
                patient_uhid: patientDetails.uhid,
                patient_gender: patientDetails.gender,
                patient_gender_value: patientDetails.gender === 1 ? 'Male' : 'Female' || patientDetails.gender === 3 && 'Transgender'
            },
            rpt_hospital_data: {
                hospital_id: hospitalDetails.id,
                hospital_name: hospitalDetails.hospitalname
            },
            rpt_doctor_data: {
                referrer_id: hospitalDetails.referrerid,
                referrer_name: hospitalDetails.referrername
            },

            pt_name: patientDetails.patientname,
            pt_stsid: 1,
            pt_hospital_id: hospitalDetails.id,
            pt_uhid: patientDetails.uhid,
            pt_age: patientDetails.age,
            pt_gender: patientDetails.gender,
            pt_address: patientDetails.address,
            pt_city: patientDetails.city,
            pt_contact_number: patientDetails.contact_no,
            rpt_referrer_id: hospitalDetails.referrerid,
            rpt_hospital_id: hospitalDetails.id,
            rpt_patient_id: patientDetails.id,
            updated_by: updatedBy !== null ? Number(updatedBy) : updatedBy,
            approved_by: approvedBy !== null ? Number(approvedBy) : approvedBy
        }

        try {
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const response = await axios.post(process.env.REACT_APP_BASE_URL + ENDPOINT.REPORTSAVE, req, config);
            setButtonDisabled(false)
            console.log('res', response)
            if (response) {

                if (response && response?.data?.body?.statusFlag === 3) {
                    toast.error(response?.data?.body?.message, { autoClose: 2000 });
                    setTimeout(() => {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    }, 2000);
                }

                if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                    const refresh = await getRefreshToken();

                    if (refresh) {
                        if (Number(refresh) === constants.invalidRefreshToken) {
                            localStorage.removeItem("authUser");
                            navigate('/login')
                        } else {
                            await reportApprove();
                        }
                    }
                }

                setStatusFlag(response?.data?.body?.statusFlag)
                if (response && response?.data?.body?.statusFlag === 4) {
                    // navigate('/report-request')
                    toast.success(response?.data?.body?.message, { autoClose: 2000 });
                    setTimeout(() => navigate('/report-list'), 700);
                } else if (response && response?.data?.body?.statusFlag === 2) {
                    toast.error(response?.data?.body?.message, { autoClose: 2000 });
                } else if (response && response?.data?.body?.statusFlag === 4) {
                    toast.success(response?.data?.body?.message, { autoClose: 2000 });
                }
                else {
                    toast.error(response?.data?.body?.message, { autoClose: 2000 })
                }
            }
        } catch (error) {
            setButtonDisabled(false)
            console.log('er', error)
            toast.error("Report Approve Failed", { autoClose: 2000 });
        }
    }

    const reportReject = () => {
        setButtonDisabled(true)
        const req = {
            user_id: Number(user.uid),
            rpt_update_id: Number(editId),
            ptid: Number(patientDetails.id),
            rpt_checker: 2,
            rpt_status: 3,
            process_type: "update",
            rpt_report_no: reportDetails.reportnumber,
            rpt_report_date: reportDetails.reportdate,
            rpt_echodiagram_data: {
                aorticroot_value: Number(validation.values.aorticroot) || '',
                leftatrium_value: Number(validation.values.leftatrium) || '',
                left_ventrical_diastole: Number(validation.values.leftdiastole) || '',
                systole_value: Number(validation.values.systole) || '',
                ivs_value: Number(validation.values.ivs) || '',
                lvpw_value: Number(validation.values.lvpw) || '',
                lvef_value: Number(validation.values.lvef) || '',
                right_ventrical_diastole: Number(validation.values.rightdiastole) || ''
            },
            rpt_doopler_data: {
                a_value: tab2validation.values.a || '',
                e_value: tab2validation.values.e || '',
                e_divide_a_value: ea || '',
                aortic: Number(tab2validation.values.aorticTab2) || '',
                tricuspid: Number(tab2validation.values.tricuspid) || '',
                pulmonary: Number(tab2validation.values.pulmonary) || '',
                mitral_editor_value: mitralEditorTab2 || '',
                aortic_editor_value: aorticEditorTab2 || '',
                tricuspid_editor_value: tricuspidEditorTab2 || '',
                pulmonary_editor_value: pulmonaryEditorTab2 || ''
            },
            rpt_color_doopler_data: {
                mitralvalue_editor_value: mitralValue || '',
                leftatrium_editor_value: leftAtriumValue || '',
                aorticvalue_editor_value: aorticValue || '',
                rightatrium_editor_value: rightAtriumValue || '',
                tricuspidvalue_editor_value: tricuspidValue || '',
                rightventical_editor_value: rightVentricalValue || '',
                pulmonaryvalue_editor_value: pulmonaryValue || '',
                greatvessels_editor_value: greatVesselsValue || '',
                ias_editor_value: iasValue || '',
                percardium_editor_value: percardiumValue || ''
            },
            rpt_left_ventricle: {
                leftventrical_editor_value: leftVentricalValue || ''
            },
            rpt_impression: {
                impression_editor_value: impressionValue || ''
            },
            rpt_patient_data: {
                ptid: patientDetails.id,
                patient_age: patientDetails.age,
                patient_name: patientDetails.patientname,
                patient_uhid: patientDetails.uhid,
                patient_gender: patientDetails.gender,
                patient_gender_value: patientDetails.gender === 1 ? 'Male' : 'Female' || patientDetails.gender === 3 && 'Transgender'
            },
            rpt_hospital_data: {
                hospital_id: hospitalDetails.id,
                hospital_name: hospitalDetails.hospitalname
            },
            rpt_doctor_data: {
                referrer_id: hospitalDetails.referrerid,
                referrer_name: hospitalDetails.referrername
            },

            pt_name: patientDetails.patientname,
            pt_stsid: 1,
            pt_hospital_id: hospitalDetails.id,
            pt_uhid: patientDetails.uhid,
            pt_age: patientDetails.age,
            pt_gender: patientDetails.gender,
            pt_address: patientDetails.address,
            pt_city: patientDetails.city,
            pt_contact_number: patientDetails.contact_no,
            rpt_referrer_id: hospitalDetails.referrerid,
            rpt_hospital_id: hospitalDetails.id,
            rpt_patient_id: patientDetails.id,
            updated_by: updatedBy !== null ? Number(updatedBy) : updatedBy,
            approved_by: approvedBy !== null ? Number(approvedBy) : approvedBy
        }
        createTemplate(req)
    }

    const getNormalDefault = async () => {
        // setList(data);
        setLoading(true)

        const data = {
            user_id: Number(user.uid)
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + API.DEFAULTMASTER + ENDPOINT.GETMASTERS, data, config);
            setLoading(false)

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    navigate('/login')
                }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    } else {
                        await getNormalDefault();
                    }
                }
            }

            if (response?.data?.body?.DefaultValues) {
                console.log(response.data.body.DefaultValues);
                response.data.body.DefaultValues.map((row) => {
                    if (row.def_ev_name === 'AORTIC_ROOT') {
                        setAorticRootDefault(row.def_ev_value)
                    }
                    if (row.def_ev_name === "LEFT_ATRIUM") {
                        setLeftAtriumDefault(row.def_ev_value)
                    } if (row.def_ev_name === "LEFT_VENTRICLE_DIASTOLE") {
                        setLeftVentricalDiastoleDefault(row.def_ev_value)
                    } if (row.def_ev_name === "LEFT_VENTRICLE_SYSTOLE") {
                        setSystoleDefault(row.def_ev_value)
                    } if (row.def_ev_name === "LEFT_VENTRICLE_IVS") {
                        setIvsDefault(row.def_ev_value)
                    } if (row.def_ev_name === "LEFT_VENTRICLE_LVPW") {
                        setLvpwDefault(row.def_ev_value)
                    } if (row.def_ev_name === "LEFT_VENTRICLE_LVEW") {
                        setLvefDefault(row.def_ev_value)
                    } if (row.def_ev_name === "RIGHT_VENTRICLE_DIASTOLE") {
                        setRightVentricalDiastoleDefault(row.def_ev_value)
                    }
                })
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            setLoading(false)
            console.error("Normal Default Values API Error:", error);
        }
    }

    const getReportTemplateList = async () => {
        const data = {
            user_id: Number(user.uid),
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + api.API.DEFAULTREPORT + "/" + api.ENDPOINT.GETTEMPLATE, data, config);
            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    navigate('/login')
                }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    } else {
                        await getReportTemplateList();
                    }
                }
            }

            if (response?.data?.body?.reportTemplateList) {
                setTemplateOptions(response.data.body.reportTemplateList);
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            console.error("student List API Error:", error);
        }
    }

    const numberValidation = (event) => {
        const regex = /^[0-9&/.]*$/;
        const input = event.target.value + event.key;
        const valid = regex.test(input);
        console.log('valid', valid);
        if (input.length > 10 || !valid) {
            event.preventDefault();
        }
    };

    useEffect(() => {
        if (tab2validation.values.e !== '' && tab2validation.values.a !== '') {
            var value = Number(Number(tab2validation.values.e) / Number(tab2validation.values.a)).toFixed(2)
            console.log(tab2validation.values.a, 'value');
            console.log(tab2validation.values.e, 'value');
            if (tab2validation.values.a === 'Infinity') {
                setEA(0)
            }
            else if (tab2validation.values.a === 0 || tab2validation.values.e === 0) {
                toast.error('Enter Valid Number')
                setEA(0)
            }
            else {
                if (value === 'Infinity') {
                    setEA('N/A')
                    // toast.error('Not a number')
                } else {
                    value === 'NaN' ? setEA(0) : setEA(value)
                }
            }
        }
    }, [tab2validation.values.a, tab2validation.values.e])

    const navigateList = () => {
        navigate('/report-approval')
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <ApproveModal
                show={approveModal}
                onDeleteClick={reportApprove}
                onCloseClick={() => setApproveModal(false)}
            />
            <RejectModal
                show={rejectModal}
                onDeleteClick={reportReject}
                onCloseClick={() => setRejectModal(false)}
            />
            {isLoading ?
                <Spinners setLoading={setLoading} />
                :
                <div className="page-content">
                    <Container fluid>
                        {/* <h4>Echo Detector</h4> */}
                        <div className="col-lg-12 btn-card-color"><div className="text-black-50" style={{ backgroundColor: 'rgba(80, 165, 241, 0.1)' }}><div className="card-body">
                            <p className="card-text text-black">
                                <Card className="cardDesign">
                                    <CardBody>
                                        <Row>
                                            <Col lg={4} className="sm-12">

                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        {patientDetails.gender == 1 ? <img src={avatar} alt="" className="avatar-size rounded-circle img-thumbnail" /> : <img src={girlAvatar} alt="" className="avatar-size rounded-circle img-thumbnail" />}
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <div className="d-flex">
                                                            <div className="flex-grow-1">
                                                                <div className="text-muted">
                                                                    <h6 className="mb-1">{patientDetails.patientname}</h6>
                                                                    <p className="mb-0">{patientDetails.age}yrs | {patientDetails.gender === 1 ? 'Male' : 'Female' || patientDetails.gender === 3 && 'Transgender'}</p>
                                                                    {patientDetails.uhid && <p className="mb-0">
                                                                        | UHID : {patientDetails.uhid}
                                                                    </p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <Col lg={3} sm={12}> */}
                                                    {/* </Col> */}
                                                </div>

                                            </Col>
                                            <Col lg={4} className="sm-12">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <img src={referrerAvatar} alt="" className="avatar-size rounded-circle img-thumbnail" />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <div className="d-flex">
                                                            <div className="flex-grow-1">
                                                                <div className="text-muted">
                                                                    <h6 className="mb-1">Dr.{hospitalDetails.referrername}</h6>
                                                                    <p className="mb-0">{hospitalDetails.hospitalname}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={4} className="sm-12">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <img src={calendar} alt="" className="avatar-size rounded-circle img-thumbnail" />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <div className="d-flex">
                                                            <div className="flex-grow-1">
                                                                <div className="text-muted">
                                                                    {/* <h6 className="mb-1">{patientDetails.patientname}</h6> */}
                                                                    <p className="mb-0">Report Date : {moment(reportDetails.reportdate).format("DD-MM-YYYY")}</p>
                                                                    <p className="mb-0">Report No.     {reportDetails.reportnumber}</p>
                                                                    {/* <p>UHID : 1234567890</p> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Row className="mt-3">
                                                <Col lg={2} sm={12}><label className="col-form-label">Choose Template</label></Col>
                                                <Col lg={4} sm={12}><span>  <Select
                                                    value={template}
                                                    onChange={setTemplate}
                                                    options={templateOptions.map((hp) => ({
                                                        label: hp.rpt_t_name,
                                                        value: hp.rpt_tid,
                                                    }))}
                                                    // onInputChange={handleTemplate}
                                                    className="select2-selection select-text"
                                                /></span></Col>
                                            </Row>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </p></div></div></div>
                        <TabContent
                            activeTab='1'
                            className="crypto-buy-sell-nav-content p-4"
                            style={{
                                backgroundColor: "white",
                            }}
                        >
                            <TabPane tabId="1" id="buy">
                                <Nav tabs className="nav-tabs-custom nav-justified tab-font-size">
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: langugeTab == "11",
                                            })}
                                            onClick={() => {
                                                changeLangugeTab("11")
                                                setTab1(true)
                                            }}
                                        >
                                            <span className="d-block d-sm-none">
                                                M
                                            </span>
                                            <span className="d-none d-sm-block">
                                                M-MODE ECHOCARDIOGRAM REPORT
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: langugeTab == "12",
                                            })}
                                            onClick={() => {
                                                validation.handleSubmit()
                                            }}
                                        >
                                            <span className="d-block d-sm-none">
                                                D
                                            </span>
                                            <span className="d-none d-sm-block">
                                                DOPPLER REPORT AND DOPPLER WAVEFORM ANALYSIS
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: langugeTab == "13",
                                            })}
                                            onClick={() => {
                                                tab2validation.handleSubmit()
                                            }}
                                        >
                                            <span className="d-block d-sm-none">
                                                2D
                                            </span>
                                            <span className="d-none d-sm-block">
                                                2D & COLOUR FLOW DOPPLER REPORT
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: langugeTab == "14",
                                            })}
                                            onClick={() => {
                                                tab3EditorValuesCheck()
                                            }}
                                        >
                                            <span className="d-block d-sm-none">
                                                LV
                                            </span>
                                            <span className="d-none d-sm-block">
                                                LEFT VENTRICAL & IMPRESSION COMMENT
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                    <Col
                                        lg="12"
                                        style={{
                                            backgroundColor: "white",
                                        }}
                                    >
                                        <TabContent
                                            activeTab={langugeTab}
                                            className="text-muted"
                                        >
                                            <TabPane tabId="11">
                                                <div className="card-body">
                                                    <div className="mt-3">
                                                        <Row>
                                                            <Col lg="4" className="sm-12">
                                                                <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                    <tbody>
                                                                        <tr className="table-light grid-back-color">
                                                                            <th className="text-nowrap" scope="row" style={{ backgroundColor: '#d7dade' }}>DIMENSIONS</th>
                                                                            <th className="text-nowrap text-center" scope="row" style={{ backgroundColor: '#d7dade' }}>VALUES</th>
                                                                            <th className="text-nowrap" scope="row" style={{ backgroundColor: '#d7dade' }}>NORMAL</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="td-align">AORTIC ROOT(ED)<span className="text-danger">*</span></td>
                                                                            <td className="td-align"><div className="input-group">
                                                                                <Input className="form-control grid-input text-end" type="text"
                                                                                    name="aorticroot"
                                                                                    id="example-date-input"
                                                                                    onWheel={handleWheel}
                                                                                    onKeyDown={handleKeyDown}
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.aorticroot || ""}
                                                                                    invalid={validation.touched.aorticroot && validation.errors.aorticroot}
                                                                                    autoComplete="off"
                                                                                    maxLength={10}
                                                                                    onKeyPress={(e) => numberValidation(e)}
                                                                                />
                                                                                <div className="input-group-text">cm</div></div>
                                                                            </td>
                                                                            <td className="td-align">{aorticRootDefault}</td>
                                                                            {validation.touched.aorticroot && validation.errors.aorticroot ? (
                                                                                <FormFeedback type="invalid">{validation.errors.aorticroot}</FormFeedback>
                                                                            ) : null}
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="td-align">LEFT ATRIUM(ES)<span className="text-danger">*</span></td>
                                                                            <td className="td-align"><div className="input-group">
                                                                                <Input className="form-control grid-input text-end" type="text" id="example-date-input"
                                                                                    name="leftatrium"
                                                                                    onWheel={handleWheel}
                                                                                    onKeyDown={handleKeyDown}
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.leftatrium || ""}
                                                                                    invalid={validation.touched.leftatrium && validation.errors.leftatrium}
                                                                                    autoComplete="off"
                                                                                    maxLength={10}
                                                                                    onKeyPress={(e) => numberValidation(e)}
                                                                                /><div className="input-group-text">cm</div></div></td>
                                                                            <td className="td-align">{leftAtriumDefault}</td>
                                                                        </tr>
                                                                        {validation.touched.leftatrium && validation.errors.leftatrium ? (
                                                                            <FormFeedback type="invalid">{validation.errors.leftatrium}</FormFeedback>
                                                                        ) : null}
                                                                    </tbody>
                                                                </table>
                                                            </Col>
                                                            <Col lg="4" className="sm-12">
                                                                <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                    <tbody>
                                                                        <tr className="table-light grid-back-color">
                                                                            <th className="text-nowrap" scope="row">LEFT VENTRICAL</th>
                                                                            <th className="text-nowrap text-center" scope="row">VALUES</th>
                                                                            <th className="text-nowrap" scope="row">NORMAL</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="td-align">DIASTOLE<span className="text-danger">*</span></td>
                                                                            <td className="td-align"><div className="input-group">
                                                                                <Input className="form-control grid-input text-end" type="text" id="example-date-input"
                                                                                    name="leftdiastole"
                                                                                    onWheel={handleWheel}
                                                                                    onKeyDown={handleKeyDown}
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.leftdiastole || ""}
                                                                                    invalid={validation.touched.leftdiastole && validation.errors.leftdiastole}
                                                                                    autoComplete="off"
                                                                                    maxLength={10}
                                                                                    onKeyPress={(e) => numberValidation(e)}
                                                                                /><div className="input-group-text">cm</div></div></td>
                                                                            <td className="td-align">{leftVentricalDiastoleDefault}</td>
                                                                        </tr>
                                                                        {validation.touched.leftdiastole && validation.errors.leftdiastole ? (
                                                                            <FormFeedback type="invalid">{validation.errors.leftdiastole}</FormFeedback>
                                                                        ) : null}
                                                                        <tr>
                                                                            <td className="td-align">SYSTOLE<span className="text-danger">*</span></td>
                                                                            <td className="td-align"><div className="input-group">
                                                                                <Input className="form-control grid-input text-end" type="text" id="example-date-input"
                                                                                    name="systole"
                                                                                    onWheel={handleWheel}
                                                                                    onKeyDown={handleKeyDown}
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.systole || ""}
                                                                                    invalid={validation.touched.systole && validation.errors.systole}
                                                                                    autoComplete="off"
                                                                                    maxLength={10}
                                                                                    onKeyPress={(e) => numberValidation(e)}
                                                                                /><div className="input-group-text">cm</div></div></td>
                                                                            <td className="td-align">{systoleDefault}</td>
                                                                        </tr>
                                                                        {validation.touched.systole && validation.errors.systole ? (
                                                                            <FormFeedback type="invalid">{validation.errors.systole}</FormFeedback>
                                                                        ) : null}
                                                                        <tr>
                                                                            <td className="td-align">IVS(D)<span className="text-danger">*</span></td>
                                                                            <td className="td-align"><div className="input-group">
                                                                                <Input className="form-control grid-input text-end" type="text" id="example-date-input"
                                                                                    name="ivs"
                                                                                    onWheel={handleWheel}
                                                                                    onKeyDown={handleKeyDown}
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.ivs || ""}
                                                                                    invalid={validation.touched.ivs && validation.errors.ivs}
                                                                                    autoComplete="off"
                                                                                    maxLength={10}
                                                                                    onKeyPress={(e) => numberValidation(e)}
                                                                                />
                                                                                <div className="input-group-text">cm</div></div></td>
                                                                            <td className="td-align">{ivsDefault}</td>
                                                                        </tr>
                                                                        {validation.touched.ivs && validation.errors.ivs ? (
                                                                            <FormFeedback type="invalid">{validation.errors.ivs}</FormFeedback>
                                                                        ) : null}
                                                                        <tr>
                                                                            <td className="td-align">LVPW(D)<span className="text-danger">*</span></td>
                                                                            <td className="td-align"><div className="input-group">
                                                                                <Input className="form-control grid-input text-end" type="text" id="example-date-input"
                                                                                    name="lvpw"
                                                                                    onWheel={handleWheel}
                                                                                    onKeyDown={handleKeyDown}
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.lvpw || ""}
                                                                                    invalid={validation.touched.lvpw && validation.errors.lvpw}
                                                                                    autoComplete="off"
                                                                                    maxLength={10}
                                                                                    onKeyPress={(e) => numberValidation(e)}
                                                                                /><div className="input-group-text">cm</div></div></td>
                                                                            <td className="td-align">{lvpwDefault}</td>
                                                                        </tr>
                                                                        {validation.touched.lvpw && validation.errors.lvpw ? (
                                                                            <FormFeedback type="invalid">{validation.errors.lvpw}</FormFeedback>
                                                                        ) : null}
                                                                        <tr>
                                                                            <td className="td-align">LV EF<span className="text-danger">*</span></td>
                                                                            <td className="td-align"><div className="input-group">
                                                                                <Input className="form-control grid-input text-end" type="text" id="example-date-input"
                                                                                    name="lvef"
                                                                                    onWheel={handleWheel}
                                                                                    onKeyDown={handleKeyDown}
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.lvef || ""}
                                                                                    invalid={validation.touched.lvef && validation.errors.lvef}
                                                                                    autoComplete="off"
                                                                                    maxLength={10}
                                                                                    onKeyPress={(e) => numberValidation(e)}
                                                                                /><div className="input-group-text">%</div></div></td>
                                                                            <td className="td-align">{lvefDefault}</td>
                                                                        </tr>
                                                                        {validation.touched.lvef && validation.errors.lvef ? (
                                                                            <FormFeedback type="invalid">{validation.errors.lvef}</FormFeedback>
                                                                        ) : null}
                                                                    </tbody>
                                                                </table>
                                                            </Col>
                                                            <Col lg="4" className="sm-12">
                                                                <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                    <tbody>
                                                                        <tr className="table-light grid-back-color">
                                                                            <th className="text-nowrap" scope="row">RIGHT VENTRICAL</th>
                                                                            <th className="text-nowrap text-center" scope="row">VALUES</th>
                                                                            <th className="text-nowrap" scope="row">NORMAL</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="td-align">DIASTOLE<span className="text-danger">*</span></td>
                                                                            <td className="td-align"><div className="input-group">
                                                                                <Input className="form-control grid-input text-end" type="text" id="example-date-input"
                                                                                    name="rightdiastole"
                                                                                    onWheel={handleWheel}
                                                                                    onKeyDown={handleKeyDown}
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.rightdiastole || ""}
                                                                                    invalid={validation.touched.rightdiastole && validation.errors.rightdiastole}
                                                                                    autoComplete="off"
                                                                                    maxLength={10}
                                                                                    onKeyPress={(e) => numberValidation(e)}
                                                                                /><div className="input-group-text">cm</div></div></td>
                                                                            <td className="td-align">{rightVentricalDiastoleDefault}</td>
                                                                        </tr>
                                                                        {validation.touched.rightdiastole && validation.errors.rightdiastole ? (
                                                                            <FormFeedback type="invalid">{validation.errors.rightdiastole}</FormFeedback>
                                                                        ) : null}
                                                                    </tbody>
                                                                </table>
                                                                <div className="text-end mt-2 mb-3">
                                                                    <Button
                                                                        color="info"
                                                                        type="submit"
                                                                        className="save-and-continue-user me-2"
                                                                        onClick={() => navigate('/report-request')}
                                                                    // style={{ marginLeft: '10px' }}
                                                                    // disabled={isButtonDisabled}

                                                                    >
                                                                        Close
                                                                    </Button>
                                                                    <Button
                                                                        color="success"
                                                                        type="submit"
                                                                        className="save-and-continue-user me-2"
                                                                        onClick={
                                                                            validation.handleSubmit

                                                                            // changeLangugeTab("12")
                                                                            // setTab2(true)
                                                                        }
                                                                    // style={{ marginLeft: '10px' }}
                                                                    // disabled={isButtonDisabled}

                                                                    >
                                                                        Next
                                                                    </Button>
                                                                    {/* <Button
                                                                        color="success"
                                                                        // type="submit"
                                                                        className="save-user"
                                                                        onClick={saveReport}
                                                                    // disabled={isButtonDisabled}
                                                                    >
                                                                        {isEdit ? "Update" : "Save"}
                                                                    </Button> */}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="12">
                                                <div className="card-body">
                                                    <Row className="mt-4">
                                                        <Col lg="3" className="col-sm-12">
                                                            <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                <tbody>
                                                                    <tr className="table-light grid-back-color">
                                                                        <th className="text-nowrap text-center report-th" scope="row">MITRAL<span className="text-danger">*</span></th>
                                                                        <th>
                                                                            <Input className="form-control grid-input" type="text" id="example-date-input"
                                                                                name="e"
                                                                                placeholder="E"
                                                                                onWheel={handleWheel}
                                                                                onKeyDown={handleKeyDown}
                                                                                onChange={tab2validation.handleChange}
                                                                                onBlur={tab2validation.handleBlur}
                                                                                value={tab2validation.values.e || ""}
                                                                                invalid={tab2validation.touched.e && tab2validation.errors.e}
                                                                                autoComplete="off"
                                                                                maxLength={10}
                                                                                onKeyPress={(e) => numberValidation(e)}
                                                                            />
                                                                            <Input className="form-control grid-input" type="text" id="example-date-input"
                                                                                name="a"
                                                                                placeholder="A"
                                                                                onWheel={handleWheel}
                                                                                onKeyDown={handleKeyDown}
                                                                                onChange={tab2validation.handleChange}
                                                                                onBlur={tab2validation.handleBlur}
                                                                                value={tab2validation.values.a || ""}
                                                                                invalid={tab2validation.touched.a && tab2validation.errors.a}
                                                                                autoComplete="off"
                                                                                maxLength={10}
                                                                                onKeyPress={(e) => numberValidation(e)}
                                                                            />
                                                                        </th>
                                                                        {tab2validation.touched.e && tab2validation.errors.e ? (
                                                                            <FormFeedback type="invalid">{tab2validation.errors.e}</FormFeedback>
                                                                        ) : null}
                                                                        {tab2validation.touched.a && tab2validation.errors.a ? (
                                                                            <FormFeedback type="invalid">{tab2validation.errors.a}</FormFeedback>
                                                                        ) : null}
                                                                        <th className="input-width report-th">
                                                                            E/A: <span className="text-bold">{ea}</span>
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={3} style={{ height: "175px" }}>
                                                                            {/* <TextEditor /> */}
                                                                            <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                                <ReactQuill theme="snow"
                                                                                    ref={mitralEditorTab2Ref}
                                                                                    modules={modules}
                                                                                    formats={formats}
                                                                                    style={{ height: "135px" }
                                                                                    }
                                                                                    onChange={setMitralEditorTab2}
                                                                                    value={mitralEditorTab2}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                        <Col lg="3" className="col-sm-12">
                                                            <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                <tbody>
                                                                    <tr className="table-light grid-back-color">
                                                                        <th className="text-nowrap text-center report-th" scope="row">AORTIC<span className="text-danger">*</span></th>
                                                                        <th className="report-th">
                                                                            <Input className="form-control grid-input" type="text"
                                                                                name="aorticTab2"
                                                                                id="example-date-input"
                                                                                onWheel={handleWheel}
                                                                                onKeyDown={handleKeyDown}
                                                                                onChange={tab2validation.handleChange}
                                                                                onBlur={tab2validation.handleBlur}
                                                                                value={tab2validation.values.aorticTab2 || ""}
                                                                                invalid={tab2validation.touched.aorticTab2 && tab2validation.errors.aorticTab2}
                                                                                autoComplete="off"
                                                                                maxLength={10}
                                                                                onKeyPress={(e) => numberValidation(e)}
                                                                            />
                                                                        </th>
                                                                        {tab2validation.touched.aorticTab2 && tab2validation.errors.aorticTab2 ? (
                                                                            <FormFeedback type="invalid">{tab2validation.errors.aorticTab2}</FormFeedback>
                                                                        ) : null}
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{ height: "175px" }}>
                                                                            <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                                <ReactQuill theme="snow" ref={aorticEditorTab2Ref}
                                                                                    modules={modules}
                                                                                    formats={formats}
                                                                                    style={{ height: "135px" }}
                                                                                    onChange={setAorticEditorTab2}
                                                                                    value={aorticEditorTab2}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                        <Col lg="3" className="col-sm-12">
                                                            <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                <tbody>
                                                                    <tr className="table-light grid-back-color">
                                                                        <th className="text-nowrap text-center report-th" scope="row">TRICUSPID<span className="text-danger">*</span></th>
                                                                        <th className="report-th">
                                                                            <Input className="form-control grid-input" type="text"
                                                                                onWheel={handleWheel}
                                                                                onKeyDown={handleKeyDown}
                                                                                name="tricuspid" id="example-date-input"
                                                                                onChange={tab2validation.handleChange}
                                                                                onBlur={tab2validation.handleBlur}
                                                                                value={tab2validation.values.tricuspid || ""}
                                                                                invalid={tab2validation.touched.tricuspid && tab2validation.errors.tricuspid}
                                                                                autoComplete="off"
                                                                                maxLength={10}
                                                                                onKeyPress={(e) => numberValidation(e)}
                                                                            />
                                                                        </th>
                                                                        {tab2validation.touched.tricuspid && tab2validation.errors.tricuspid ? (
                                                                            <FormFeedback type="invalid">{tab2validation.errors.tricuspid}</FormFeedback>
                                                                        ) : null}
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{ height: "175px" }}>
                                                                            <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                                <ReactQuill theme="snow" ref={tricuspidEditorTab2Ref}
                                                                                    modules={modules}
                                                                                    formats={formats}
                                                                                    style={{ height: "135px" }}
                                                                                    onChange={setTricuspidEditorTab2}
                                                                                    value={tricuspidEditorTab2}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                        <Col lg="3" className="col-sm-12">
                                                            <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                <tbody>
                                                                    <tr className="table-light grid-back-color">
                                                                        <th className="text-nowrap text-center report-th" scope="row">PULMONARY<span className="text-danger">*</span></th>
                                                                        <th className="report-th">
                                                                            <Input className="form-control grid-input" type="text"
                                                                                onWheel={handleWheel}
                                                                                onKeyDown={handleKeyDown}
                                                                                name="pulmonary" id="example-date-input"
                                                                                onChange={tab2validation.handleChange}
                                                                                onBlur={tab2validation.handleBlur}
                                                                                value={tab2validation.values.pulmonary || ""}
                                                                                invalid={tab2validation.touched.pulmonary && tab2validation.errors.pulmonary}
                                                                                autoComplete="off"
                                                                                maxLength={10}
                                                                                onKeyPress={(e) => numberValidation(e)}
                                                                            />
                                                                        </th>
                                                                        {tab2validation.touched.pulmonary && tab2validation.errors.pulmonary ? (
                                                                            <FormFeedback type="invalid">{tab2validation.errors.pulmonary}</FormFeedback>
                                                                        ) : null}
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{ height: "175px" }}>
                                                                            <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                                <ReactQuill theme="snow" ref={pulmonaryEditorTab2Ref}
                                                                                    modules={modules}
                                                                                    formats={formats}
                                                                                    style={{ height: "135px" }}
                                                                                    onChange={setPulmonaryEditorTab2}
                                                                                    value={pulmonaryEditorTab2}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div className="text-end mt-2 mb-3">
                                                                <Button
                                                                    color="info"
                                                                    type="submit"
                                                                    className="save-and-continue-user me-2"
                                                                    onClick={() => navigate('/report-request')}
                                                                // style={{ marginLeft: '10px' }}
                                                                // disabled={isButtonDisabled}

                                                                >
                                                                    Close
                                                                </Button>
                                                                <Button
                                                                    color="warning"
                                                                    // type="submit"
                                                                    className="save-user me-2"
                                                                    onClick={() => {
                                                                        changeLangugeTab("11")
                                                                        setTab1(true)
                                                                    }}
                                                                // disabled={isButtonDisabled}

                                                                >
                                                                    Previous
                                                                </Button>
                                                                <Button
                                                                    color="success"
                                                                    type="submit"
                                                                    className="save-and-continue-user me-2"
                                                                    onClick={tab2validation.handleSubmit}
                                                                // style={{ marginLeft: '10px' }}
                                                                // disabled={isButtonDisabled}

                                                                >
                                                                    Next
                                                                </Button>
                                                                {/* <Button
                                                                    color="success"
                                                                    // type="submit"
                                                                    className="save-user"
                                                                    onClick={() => saveReport()}
                                                                // disabled={isButtonDisabled}

                                                                >
                                                                    {isEdit ? "Update" : "Save"}
                                                                </Button> */}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="13">
                                                <div className="card-body">
                                                    <Row className="mt-3">
                                                        <Col lg="6" className="col-sm-12">
                                                            <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                <tbody>
                                                                    <tr className="table-light grid-back-color">
                                                                        <th className="text-nowrap text-center col-sm-2" scope="row">
                                                                            MITRAL VALVE
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Row>
                                                                                <Col md={11}>
                                                                                    <Select
                                                                                        id="mitral"
                                                                                        placeholder='Search'
                                                                                        value={mitralselectedOptions}
                                                                                        onChange={
                                                                                            setMitralSelectedOptions}

                                                                                        options={mitralOptions.map(op => ({
                                                                                            label: op.mv_value,
                                                                                            value: op.mvid,
                                                                                        }))}
                                                                                        className={`select2-selection $`}
                                                                                    // invalid={validation.touched.selectedHospital && validation.errors.selectedHospital}
                                                                                    />
                                                                                </Col>
                                                                                <Col md={1} className="div-plus-icon">
                                                                                    <div onClick={() => {
                                                                                        var mitral_value = mitralValue + mitralselectedOptions?.label

                                                                                        if (mitralselectedOptions?.label === undefined) {
                                                                                            toast.error('Please select the value')
                                                                                        } else {
                                                                                            setMitralValue(mitral_value)
                                                                                        }

                                                                                    }}
                                                                                        className="plus-icon"><i className="mdi mdi-plus mdi-14px"></i></div>
                                                                                </Col>
                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{ height: "175px" }}>
                                                                            <div style={{ backgroundColor: 'white', height: "175px" }}>

                                                                                <ReactQuill theme="snow" ref={mitralValueIframeRef}
                                                                                    modules={modules}
                                                                                    formats={formats}
                                                                                    value={mitralValue}
                                                                                    onChange={setMitralValue}
                                                                                    style={{ height: "135px" }} />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                        <Col lg="6" className="col-sm-12">
                                                            <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                <tbody>
                                                                    <tr className="table-light grid-back-color">
                                                                        <th className="text-nowrap text-center" scope="row">
                                                                            LEFT ATRIUM
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Row>
                                                                                <Col md={11}>
                                                                                    <Select
                                                                                        id="la"
                                                                                        placeholder='Search'
                                                                                        value={leftAtriumselectedOptions}
                                                                                        onChange={(selectedOption) => {
                                                                                            setLeftAtriumSelectedOptions(selectedOption)

                                                                                        }}

                                                                                        options={leftAtriumOptions.map(op => ({
                                                                                            label: op.mv_value,
                                                                                            value: op.mvid,
                                                                                        }))}
                                                                                        className={`select2-selection $`}

                                                                                    />
                                                                                </Col>
                                                                                <Col md={1} className="div-plus-icon">
                                                                                    <div onClick={() => {
                                                                                        var leftatrium_value = leftAtriumValue + leftAtriumselectedOptions?.label

                                                                                        if (leftAtriumselectedOptions?.label === undefined) {
                                                                                            toast.error('Please select the value')
                                                                                        } else {
                                                                                            setLeftAtriumValue(leftatrium_value)
                                                                                        }
                                                                                    }}
                                                                                        className="plus-icon"><i className="mdi mdi-plus mdi-14px"></i></div>
                                                                                </Col>
                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{ height: "175px" }}>
                                                                            <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                                <ReactQuill theme="snow" ref={leftAtriumIframeRef}
                                                                                    modules={modules}
                                                                                    formats={formats}
                                                                                    value={leftAtriumValue} onChange={setLeftAtriumValue}
                                                                                    style={{ height: "135px" }} />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-3">
                                                        <Col lg="6" className="col-sm-12">
                                                            <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                <tbody>
                                                                    <tr className="table-light grid-back-color">
                                                                        <th className="text-nowrap text-center" scope="row">
                                                                            AORTIC VALVE
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Row>
                                                                                <Col md={11}>
                                                                                    <Select
                                                                                        id="av"
                                                                                        placeholder='Search'
                                                                                        value={aorticValueselectedOptions}
                                                                                        onChange={(selectedOption) => {
                                                                                            setAorticValuesSelectedOptions(selectedOption)

                                                                                        }}

                                                                                        options={aorticValueOptions.map(op => ({
                                                                                            label: op.mv_value,
                                                                                            value: op.mvid,
                                                                                        }))}
                                                                                        className={`select2-selection $`}

                                                                                    />
                                                                                </Col>
                                                                                <Col md={1} className="div-plus-icon">
                                                                                    <div onClick={() => {
                                                                                        var aortic_value = aorticValue + aorticValueselectedOptions?.label
                                                                                        if (aorticValueselectedOptions?.label === undefined) {
                                                                                            toast.error('Please select the value')
                                                                                        } else {
                                                                                            setAorticValue(aortic_value)
                                                                                        }
                                                                                    }}
                                                                                        className="plus-icon"><i className="mdi mdi-plus mdi-14px"></i></div>
                                                                                </Col>
                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{ height: "175px" }}>
                                                                            <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                                <ReactQuill theme="snow"
                                                                                    ref={aorticValueIframeRef}
                                                                                    modules={modules}
                                                                                    formats={formats}
                                                                                    value={aorticValue} onChange={setAorticValue} style={{ height: "135px" }} />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                        <Col lg="6" className="col-sm-12">
                                                            <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                <tbody>
                                                                    <tr className="table-light grid-back-color">
                                                                        <th className="text-nowrap text-center" scope="row">
                                                                            RIGHT ATRIUM
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Row>
                                                                                <Col md={11}>
                                                                                    <Select
                                                                                        id="av"
                                                                                        placeholder='Search'
                                                                                        value={rightAtriumselectedOptions}
                                                                                        onChange={(selectedOption) => {
                                                                                            setRightAtriumSelectedOptions(selectedOption)

                                                                                        }}

                                                                                        options={rightAtriumOptions.map(op => ({
                                                                                            label: op.mv_value,
                                                                                            value: op.mvid,
                                                                                        }))}
                                                                                        className={`select2-selection $`}

                                                                                    />
                                                                                </Col>
                                                                                <Col md={1} className="div-plus-icon">
                                                                                    <div onClick={() => {
                                                                                        var rightatrium_value = rightAtriumValue + rightAtriumselectedOptions?.label
                                                                                        if (rightAtriumselectedOptions?.label === undefined) {
                                                                                            toast.error('Please select the value')
                                                                                        } else {
                                                                                            setRightAtriumValue(rightatrium_value)
                                                                                        }
                                                                                    }}
                                                                                        className="plus-icon"><i className="mdi mdi-plus mdi-14px"></i></div>
                                                                                </Col>
                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{ height: "175px" }}>
                                                                            <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                                <ReactQuill theme="snow"
                                                                                    ref={rightAtriumIframeRef}
                                                                                    modules={modules}
                                                                                    formats={formats}
                                                                                    value={rightAtriumValue} onChange={setRightAtriumValue}
                                                                                    style={{ height: "135px" }} />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-3">
                                                        <Col lg="6" className="col-sm-12">
                                                            <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                <tbody>
                                                                    <tr className="table-light grid-back-color">
                                                                        <th className="text-nowrap text-center" scope="row">
                                                                            TRICUSPID VALVE
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Row>
                                                                                <Col md={11}>
                                                                                    <Select
                                                                                        id="tv"
                                                                                        placeholder='Search'
                                                                                        value={tricuspidValueselectedOptions}
                                                                                        onChange={(selectedOption) => {
                                                                                            setTricuspidValueSelectedOptions(selectedOption)

                                                                                        }}

                                                                                        options={tricuspidValueOptions.map(op => ({
                                                                                            label: op.mv_value,
                                                                                            value: op.mvid,
                                                                                        }))}
                                                                                        className={`select2-selection $`}

                                                                                    />
                                                                                </Col>
                                                                                <Col md={1} className="div-plus-icon">
                                                                                    <div onClick={() => {
                                                                                        var tricuspid_value = tricuspidValue + tricuspidValueselectedOptions?.label
                                                                                        if (tricuspidValueselectedOptions?.label === undefined) {
                                                                                            toast.error('Please select the value')
                                                                                        } else {
                                                                                            setTricuspidValue(tricuspid_value)
                                                                                        }
                                                                                    }}
                                                                                        className="plus-icon"><i className="mdi mdi-plus mdi-14px"></i></div>
                                                                                </Col>
                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{ height: "175px" }}>
                                                                            <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                                <ReactQuill theme="snow"
                                                                                    ref={tricuspidValueIframeRef}
                                                                                    modules={modules}
                                                                                    formats={formats}
                                                                                    value={tricuspidValue} onChange={setTricuspidValue}
                                                                                    style={{ height: "135px" }} />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                        <Col lg="6" className="col-sm-12">
                                                            <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                <tbody>
                                                                    <tr className="table-light grid-back-color">
                                                                        <th className="text-nowrap text-center" scope="row">
                                                                            RIGHT VENTRICAL
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Row>
                                                                                <Col md={11}>
                                                                                    <Select
                                                                                        id="rv"
                                                                                        placeholder='Search'
                                                                                        value={rightVentricalselectedOptions}
                                                                                        onChange={(selectedOption) => {
                                                                                            setRightVentricalSelectedOptions(selectedOption)

                                                                                        }}

                                                                                        options={rightVentricalOptions.map(op => ({
                                                                                            label: op.mv_value,
                                                                                            value: op.mvid,
                                                                                        }))}
                                                                                        className={`select2-selection $`}

                                                                                    />
                                                                                </Col>
                                                                                <Col md={1} className="div-plus-icon">
                                                                                    <div onClick={() => {
                                                                                        var rightventrical_value = rightVentricalValue + rightVentricalselectedOptions?.label
                                                                                        if (rightVentricalselectedOptions?.label === undefined) {
                                                                                            toast.error('Please select the value')
                                                                                        } else {
                                                                                            setRightVentricalValue(rightventrical_value)
                                                                                        }
                                                                                    }}
                                                                                        className="plus-icon"><i className="mdi mdi-plus mdi-14px"></i></div>
                                                                                </Col>
                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{ height: "175px" }}>
                                                                            <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                                <ReactQuill theme="snow"
                                                                                    ref={rightVentricleValueIframeRef}
                                                                                    modules={modules}
                                                                                    formats={formats}
                                                                                    value={rightVentricalValue} onChange={setRightVentricalValue}
                                                                                    style={{ height: "135px" }} />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-3">
                                                        <Col lg="6" className="col-sm-12">
                                                            <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                <tbody>
                                                                    <tr className="table-light grid-back-color">
                                                                        <th className="text-nowrap text-center" scope="row">
                                                                            PULMONARY VALVE
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Row>
                                                                                <Col md={11}>
                                                                                    <Select
                                                                                        id="pv"
                                                                                        placeholder='Search'
                                                                                        value={pulmonaryValueselectedOptions}
                                                                                        onChange={(selectedOption) => {
                                                                                            setPulmonaryValueSelectedOptions(selectedOption)

                                                                                        }}

                                                                                        options={pulmonaryValueOptions.map(op => ({
                                                                                            label: op.mv_value,
                                                                                            value: op.mvid,
                                                                                        }))}
                                                                                        className={`select2-selection $`}

                                                                                    />
                                                                                </Col>
                                                                                <Col md={1} className="div-plus-icon">
                                                                                    <div onClick={() => {
                                                                                        var pulmonary_value = pulmonaryValue + pulmonaryValueselectedOptions?.label
                                                                                        if (pulmonaryValueselectedOptions?.label === undefined) {
                                                                                            toast.error('Please select the value')
                                                                                        } else {
                                                                                            setPulmonaryValue(pulmonary_value)
                                                                                        }
                                                                                    }}
                                                                                        className="plus-icon"><i className="mdi mdi-plus mdi-14px"></i></div>
                                                                                </Col>
                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{ height: "175px" }}>
                                                                            <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                                <ReactQuill theme="snow"
                                                                                    ref={pulmonaryValueIframeRef}
                                                                                    modules={modules}
                                                                                    formats={formats}
                                                                                    value={pulmonaryValue} onChange={setPulmonaryValue}
                                                                                    style={{ height: "135px" }} />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                        <Col lg="6" className="col-sm-12">
                                                            <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                <tbody>
                                                                    <tr className="table-light grid-back-color">
                                                                        <th className="text-nowrap text-center" scope="row">
                                                                            GREAT VESSELS
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Row>
                                                                                <Col md={11}>
                                                                                    <Select
                                                                                        id="gv"
                                                                                        placeholder='Search'
                                                                                        value={greatselectedOptions}
                                                                                        onChange={(selectedOption) => {
                                                                                            setGreatSelectedOptions(selectedOption)

                                                                                        }}

                                                                                        options={greatOptions.map(op => ({
                                                                                            label: op.mv_value,
                                                                                            value: op.mvid,
                                                                                        }))}
                                                                                        className={`select2-selection $`}

                                                                                    />
                                                                                </Col>
                                                                                <Col md={1} className="div-plus-icon">
                                                                                    <div onClick={() => {
                                                                                        var greatvessels_value = greatVesselsValue + greatselectedOptions?.label
                                                                                        if (greatselectedOptions?.label === undefined) {
                                                                                            toast.error('Please select the value')
                                                                                        } else {
                                                                                            setGreatVesselsValue(greatvessels_value)
                                                                                        }
                                                                                    }}
                                                                                        className="plus-icon"><i className="mdi mdi-plus mdi-14px"></i></div>
                                                                                </Col>
                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{ height: "175px" }}>
                                                                            <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                                <ReactQuill theme="snow"
                                                                                    ref={greatvesselsValueIframeRef}
                                                                                    modules={modules}
                                                                                    formats={formats}
                                                                                    value={greatVesselsValue} onChange={setGreatVesselsValue}
                                                                                    style={{ height: "135px" }} />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-3">
                                                        <Col lg="6" className="col-sm-12">
                                                            <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                <tbody>
                                                                    <tr className="table-light grid-back-color">
                                                                        <th className="text-nowrap text-center" scope="row">
                                                                            IAS & IVS
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Row>
                                                                                <Col md={11}>
                                                                                    <Select
                                                                                        id="iav"
                                                                                        placeholder='Search'
                                                                                        value={iasselectedOptions}
                                                                                        onChange={(selectedOption) => {
                                                                                            setIasSelectedOptions(selectedOption)

                                                                                        }}

                                                                                        options={iasOptions.map(op => ({
                                                                                            label: op.mv_value,
                                                                                            value: op.mvid,
                                                                                        }))}
                                                                                        className={`select2-selection $`}

                                                                                    />
                                                                                </Col>
                                                                                <Col md={1} className="div-plus-icon">
                                                                                    <div onClick={() => {
                                                                                        var ias_value = iasValue + iasselectedOptions?.label
                                                                                        if (iasselectedOptions?.label === undefined) {
                                                                                            toast.error('Please select the value')
                                                                                        } else {
                                                                                            setIasValue(ias_value)
                                                                                        }
                                                                                    }}
                                                                                        className="plus-icon"><i className="mdi mdi-plus mdi-14px"></i></div>
                                                                                </Col>
                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{ height: "175px" }}>
                                                                            <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                                <ReactQuill theme="snow"
                                                                                    ref={iasValueIframeRef}
                                                                                    modules={modules}
                                                                                    formats={formats}
                                                                                    value={iasValue} onChange={setIasValue}
                                                                                    style={{ height: "135px" }} />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                        <Col lg="6" className="col-sm-12">
                                                            <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                <tbody>
                                                                    <tr className="table-light grid-back-color">
                                                                        <th className="text-nowrap text-center" scope="row">
                                                                            PERICARDIUM
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Row>
                                                                                <Col md={11}>
                                                                                    <Select
                                                                                        id="pv"
                                                                                        placeholder='Search'
                                                                                        value={percardiumselectedOptions}
                                                                                        onChange={(selectedOption) => {
                                                                                            setPercardiumSelectedOptions(selectedOption)

                                                                                        }}

                                                                                        options={percardiumOptions.map(op => ({
                                                                                            label: op.mv_value,
                                                                                            value: op.mvid,
                                                                                        }))}
                                                                                        className={`select2-selection $`}

                                                                                    />
                                                                                </Col>
                                                                                <Col md={1} className="div-plus-icon">
                                                                                    <div onClick={() => {
                                                                                        var percardium_value = percardiumValue + percardiumselectedOptions?.label
                                                                                        console.log('percardium_value', percardiumselectedOptions.label);
                                                                                        if (percardiumselectedOptions?.label === undefined) {
                                                                                            toast.error('Please select the value')
                                                                                        } else {
                                                                                            setPercardiumValue(percardium_value)
                                                                                        }
                                                                                    }}
                                                                                        className="plus-icon"><i className="mdi mdi-plus mdi-14px"></i></div>
                                                                                </Col>
                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{ height: "175px" }}>
                                                                            <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                                <ReactQuill theme="snow"
                                                                                    ref={pericardiumValueIframeRef}
                                                                                    modules={modules}
                                                                                    formats={formats}
                                                                                    value={percardiumValue} onChange={setPercardiumValue}
                                                                                    style={{ height: "135px" }} />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div className="text-end mt-2 mb-3">
                                                                <Button
                                                                    color="info"
                                                                    type="submit"
                                                                    className="save-and-continue-user me-2"
                                                                    onClick={() => navigate('/report-request')}
                                                                // style={{ marginLeft: '10px' }}
                                                                // disabled={isButtonDisabled}

                                                                >
                                                                    Close
                                                                </Button>
                                                                <Button
                                                                    color="warning"
                                                                    // type="submit"
                                                                    className="save-user me-2"
                                                                    onClick={() => {
                                                                        changeLangugeTab("12")
                                                                        setTab2(true)
                                                                    }}
                                                                // disabled={isButtonDisabled}

                                                                >
                                                                    Previous
                                                                </Button>
                                                                <Button
                                                                    color="success"
                                                                    type="submit"
                                                                    className="save-and-continue-user me-2"
                                                                    onClick={tab3EditorValuesCheck}
                                                                // style={{ marginLeft: '10px' }}
                                                                // disabled={isButtonDisabled}

                                                                >
                                                                    Next
                                                                </Button>
                                                                {/* <Button
                                                                    color="success"
                                                                    // type="submit"
                                                                    className="save-user"
                                                                    onClick={() => saveReport()}
                                                                // disabled={isButtonDisabled}

                                                                >
                                                                    Save
                                                            </Button> */}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="14">
                                                <div className="card-body">
                                                    <Row className="mt-3">
                                                        <Col lg="6" className="col-sm-12">
                                                            <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                <tbody>
                                                                    <tr className="table-light grid-back-color">
                                                                        <th className="text-nowrap text-center" scope="row">
                                                                            LEFT VENTRICAL
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Row>
                                                                                <Col md={11}>
                                                                                    <Select
                                                                                        id="lfv"
                                                                                        placeholder='Search'
                                                                                        value={leftVentricalselectedOptions}
                                                                                        onChange={(selectedOption) => {
                                                                                            setLeftVentricalSelectedOptions(selectedOption)

                                                                                        }}

                                                                                        options={leftVentricalOptions.map(op => ({
                                                                                            label: op.mv_value,
                                                                                            value: op.mvid,
                                                                                        }))}
                                                                                        className={`select2-selection $`}

                                                                                    />
                                                                                </Col>
                                                                                <Col md={1} className="div-plus-icon">
                                                                                    <div onClick={() => {
                                                                                        var leftventrical_value = leftVentricalValue + leftVentricalselectedOptions?.label

                                                                                        if (leftVentricalselectedOptions?.label === undefined) {
                                                                                            toast.error('Please select the value')
                                                                                        } else {
                                                                                            setLeftVentricalValue(leftventrical_value)
                                                                                        }
                                                                                    }}
                                                                                        className="plus-icon"><i className="mdi mdi-plus mdi-14px"></i></div>
                                                                                </Col>
                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{ height: "175px" }}>
                                                                            <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                                <ReactQuill theme="snow" ref={leftventricleValueIframeRef}
                                                                                    modules={modules}
                                                                                    formats={formats}
                                                                                    value={leftVentricalValue} onChange={setLeftVentricalValue}
                                                                                    style={{ height: "135px" }} />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                        <Col lg="6" className="col-sm-12">
                                                            <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                <tbody>
                                                                    <tr className="table-light grid-back-color">
                                                                        <th className="text-nowrap text-center" scope="row">
                                                                            IMPRESSION COMMENT
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Row>
                                                                                <Col md={11}>
                                                                                    <Select
                                                                                        id="ic"
                                                                                        placeholder='Search'
                                                                                        value={impressionselectedOptions}
                                                                                        onChange={(selectedOption) => {
                                                                                            setImpressionSelectedOptions(selectedOption)

                                                                                        }}

                                                                                        options={impressionOptions.map(op => ({
                                                                                            label: op.mv_value,
                                                                                            value: op.mvid,
                                                                                        }))}
                                                                                        className={`select2-selection $`}

                                                                                    /></Col>
                                                                                <Col md={1} className="div-plus-icon"> <div onClick={() => {
                                                                                    var impression_value = impressionValue + impressionselectedOptions?.label

                                                                                    if (impressionselectedOptions?.label
                                                                                        === undefined) {
                                                                                        toast.error('Please select the value')
                                                                                    } else {
                                                                                        setImpressionValue(impression_value)
                                                                                    }
                                                                                }}
                                                                                    className="plus-icon"><i className="mdi mdi-plus mdi-14px"></i></div></Col>
                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{ height: "175px" }}>
                                                                            <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                                <ReactQuill theme="snow"
                                                                                    ref={impressionValueIframeRef}
                                                                                    modules={modules}
                                                                                    formats={formats}
                                                                                    value={impressionValue} onChange={setImpressionValue}
                                                                                    style={{ height: "135px" }} />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                        <Row>
                                                            <Col lg={6} sm={12}>
                                                                <div>
                                                                    {reportStatus === 1 && <>
                                                                        <Button
                                                                            color="warning"
                                                                            // type="submit"
                                                                            className="save-user me-2"
                                                                            onClick={() => {
                                                                                changeLangugeTab("13")
                                                                                setTab3(true)
                                                                            }}
                                                                        // disabled={isButtonDisabled}

                                                                        >
                                                                            Previous
                                                                        </Button>
                                                                        <Button
                                                                            color="info"
                                                                            type="submit"
                                                                            className="save-and-continue-user me-2"
                                                                            onClick={navigateList}
                                                                        // style={{ marginLeft: '10px' }}
                                                                        // disabled={isButtonDisabled}

                                                                        >
                                                                            Close
                                                                        </Button></>
                                                                    }
                                                                </div>
                                                            </Col>
                                                            <Col lg={6} sm={12}>
                                                                <div className="text-end mt-2 mb-3">

                                                                    {reportStatus === 1 ? <>
                                                                        <Button
                                                                            color="success"
                                                                            // type="submit"
                                                                            className="save-user me-2"
                                                                            onClick={() => {
                                                                                tab4EditorValuesCheck(2)
                                                                            }}
                                                                            disabled={isButtonDisabled}
                                                                        >
                                                                            Approve
                                                                        </Button>
                                                                        <Button
                                                                            color="danger"
                                                                            className="save-user"
                                                                            onClick={() => {
                                                                                tab4EditorValuesCheck(3)
                                                                            }}
                                                                            disabled={isButtonDisabled}
                                                                        >
                                                                            Reject
                                                                        </Button></> : <>

                                                                        <Button
                                                                            color="warning"
                                                                            // type="submit"
                                                                            className="save-user me-2"
                                                                            onClick={() => {
                                                                                changeLangugeTab("13")
                                                                                setTab3(true)
                                                                            }}
                                                                        // disabled={isButtonDisabled}

                                                                        >
                                                                            Previous
                                                                        </Button>
                                                                        <Button
                                                                            color="success"
                                                                            className="save-user"
                                                                            onClick={() => {
                                                                                tab4EditorValuesCheck(1)
                                                                            }}
                                                                            disabled={isButtonDisabled}
                                                                        >
                                                                            Save
                                                                        </Button></>}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Row>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </Col>
                                </Nav>
                            </TabPane>
                        </TabContent>
                        {/* <Row style={{ textAlign: 'left' }}>
                            <Col xs={12}>
                                <Button
                                    color="info"
                                    type="submit"
                                    className="save-and-continue-user me-2"
                                    onClick={() => navigate('/report-request')}
                                // style={{ marginLeft: '10px' }}
                                // disabled={isButtonDisabled}

                                >
                                    Close
                                </Button>
                            </Col>

                        </Row> */}

                    </Container>
                </div >
            }
        </React.Fragment >
    );
};

export default withTranslation()(EchoReportEntry);
